import Splide from '@splidejs/splide';
export default class TopSectionIssues {
    static exec() {
        const els = document.querySelectorAll('[data-component="top-section-issues"]');
        els.forEach(function (el) {
            new TopSectionIssues(el);
        });
    }

    el;
    splides;
    constructor(element) {
        const self = this;
        this.el = element;
        const splide_els = element.querySelectorAll('[data-splide-el="splide"]');
        splide_els.forEach(function (el) {
            const splide = new Splide(el, {
                type: 'fade',
                speed: 1000,
                arrows: true,
                autoplay: false,
                pagination: false
            }).mount();
        });

        const children_els = element.querySelectorAll('[data-el="splide-children"]');
        const parent_els = element.querySelectorAll('[data-el="splide-parents"] [data-category]');
        parent_els.forEach(function (el) {
            el.addEventListener('click', function () {
                element.querySelector('[data-el="splide-parents"]').dataset.state = 'close';
                children_els.forEach(function (el) {
                    el.dataset.state = 'close';
                });
                const term_id = this.dataset.category;
                const child_el = self.el.querySelector('[data-el="splide-children"][data-category="' + term_id + '"]');
                child_el.dataset.state = 'open';
            })
        });

        const parent_button_els = element.querySelectorAll('[data-el="button-parent"]');
        parent_button_els.forEach(function (el) {
            el.addEventListener('click', function () {
                element.querySelector('[data-el="splide-parents"]').dataset.state = 'open';
                children_els.forEach(function (el) {
                    el.dataset.state = 'close';
                });
            })
        });
    }
}