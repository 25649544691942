import TopSectionIssues from "./component/top-section-issues";
import TopSectionCategories from "./component/top-section-categories";
import TopSectionRanking from "./component/top-section-ranking";
import TopSectionLatest from "./component/top-section-latest";
import TopSectionFavorite from "./component/top-section-favorite";
import TopSectionHistories from "./component/top-section-histories";
import TopSectionMovies from "./component/top-section-movies";
import TopSectionGuide from "./component/top-section-guide";
import TopSectionInfo from "./component/top-section-info";
import TopSectionBanner from "./component/top-section-banner";
import TopSectionLogin from "./component/top-section-login";
import TopSectionSearch from "./component/top-section-search";
import TopSectionNewerServices from "./component/top-section-newer-services";

document.addEventListener('DOMContentLoaded', function (){
    TopSectionIssues.exec();
    TopSectionCategories.exec();
    TopSectionRanking.exec();
    TopSectionLatest.exec();
    TopSectionFavorite.exec();
    TopSectionHistories.exec();
    TopSectionMovies.exec();
    TopSectionGuide.exec();
    TopSectionInfo.exec();
    TopSectionBanner.exec();
    TopSectionLogin.exec();
    TopSectionSearch.exec();
    TopSectionNewerServices.exec();
});