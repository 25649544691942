import Splide from '@splidejs/splide';
export default class TopSectionLogin {
    static exec() {
        const els = document.querySelectorAll('[data-component="top-section-login"]');
        els.forEach(function (el) {
            new TopSectionLogin(el);
        });
    }

    el;
    constructor(element) {
        const self = this;
        this.el = element;
    }
}