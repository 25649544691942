import Splide from '@splidejs/splide';
export default class TopSectionNewerServices {
    static exec() {
        const els = document.querySelectorAll('[data-component="top-section-newer-services"]');
        els.forEach(function (el) {
            new TopSectionNewerServices(el);
        });
    }

    el;
    constructor(element) {
        const self = this;
        this.el = element;
        const splide_els = element.querySelectorAll('[data-el="splide"]');
        splide_els.forEach(function (el) {
            const splide = new Splide(el, {
                type: 'fade',
                speed: 1000,
                arrows: true,
                autoplay: false,
                pagination: false
            }).mount();
        });
    }
}