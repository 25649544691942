import Splide from '@splidejs/splide';
export default class TopSectionSearch {
    static exec() {
        const els = document.querySelectorAll('[data-component="top-section-search"]');
        els.forEach(function (el) {
            new TopSectionSearch(el);
        });
    }

    el;
    constructor(element) {
        const self = this;
        this.el = element;
        let button = element.querySelector('[data-el="search-button"]');
        button.addEventListener('click', function() {
            let form = element.querySelector('[data-el="search-form"]');
            form.submit();
        });
    }
}